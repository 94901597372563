import { render, staticRenderFns } from "./channel-bottom.vue?vue&type=template&id=bb7adeda&scoped=true&"
import script from "./channel-bottom.vue?vue&type=script&lang=js&"
export * from "./channel-bottom.vue?vue&type=script&lang=js&"
import style0 from "./channel-bottom.vue?vue&type=style&index=0&id=bb7adeda&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb7adeda",
  null
  
)

export default component.exports