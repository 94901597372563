<template>
  <div class="build-settings-container box-shadow-light-grey border-radius-6">
    <h3>生成详情</h3>
    <div class="container">
      <div class="build-settings-part">
        <div class="part">
          <span>选择书籍</span>
          <el-select v-model="categoryId" placeholder="请选择书籍分类" style='width:200px'>
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in categoryList"
              :key="item.id"
            >
            </el-option>
          </el-select>
          <div class="choose-book">
            <el-input v-model="bookName" placeholder="请输入书籍名称" style='width:300px'>
            </el-input>
            <div class="book-container" v-show="isShowFilterBook">
              <div class="book-wrapper" style="height:260px">
                <ul ref="bookWrapper" class="ul-book-wrapper">
                  <li v-for="item in filterBookList" :key="item.id">
                    <label @click="chooseBookItem(item)">
                      <span>{{ item.bookName }}</span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="build-settings-part">
        <span>计划名称</span>
        <el-input
          style="width:300px"
          v-model="projectName"
          :placeholder="`${!bookName ? '请先选择书籍' : '请输入计划名称'}`"
          :disabled="!bookName"
          ref="input"
        >
          <i
            class="el-icon-edit el-input__icon"
            slot="suffix"
            @click="handleIconClick"
          >
          </i>
        </el-input>
      </div>
      <div class="build-settings-part">
        <span>计划中顶图数量 <i class='iconfont icon-tishi' title='如果是选择的公众平台和激励模式，则只能勾选一张的规格!'></i></span>
        <el-checkbox-group v-model="topImageNum">
          <el-checkbox :label="1">1张</el-checkbox>
          <el-checkbox
            :label="3"
            :disabled="checkDisabledStatus(3)"
            >3张</el-checkbox
          >
          <el-checkbox
            :label="4"
            :disabled="
              checkDisabledStatus(4)
            "
            >4张</el-checkbox
          >
          <el-checkbox
            :label="6"
            :disabled="
              checkDisabledStatus(6)
            "
            >6张</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div class="build-settings-part">
        <span>计划数量 <i class='iconfont icon-tishi' title='不能大于计划当中的顶图数量！'></i></span>
        <el-input-number
          v-model="projectNum"
          :min="1"
          :max="maxNumber"
          :step="1"
          size="mini"
        ></el-input-number>
      </div>
      <div class="build-settings-part">
        <span>计划中广告数量 <i class='iconfont icon-tishi' title='不能大于本次添加的定向人群数量！'></i></span>
        <el-input-number
          v-model="minProjectAdNum"
          :min="1"
          :max="maxProjectAdNum"
          size="mini"
        ></el-input-number>
        <span
          @click="handleChooseMore"
          style="color:#169BD5;cursor:pointer;margin-left:10px"
          ></span
        >
      </div>
      <div class="build-settings-part">
        <span style="align-self:flex-start">每日预算</span>
        <div>
          <el-input-number
            v-model="dayBudget"
            style="width:200px"
            :precision="2"
          ></el-input-number
          ><span style="margin-left:5px">元/天</span>
          <p style="color:#A6A6A6;padding-top:10px">
            每日预算有效范围为50-40,000,000元/天
          </p>
        </div>
      </div>
      <div class="build-settings-part">
        <span style="align-self:flex-start">出价</span>
        <div>
          <el-input-number
            style="width:200px"
            v-model="bid"
            :precision="2"
          ></el-input-number
          ><span style="margin-left:5px">元/下单</span>
          <p style="color:#A6A6A6;padding-top:10px">
            <span>出价范围0.1-5000元/下单</span>
          </p>
        </div>
      </div>
      <div class="build-settings-part">
        <span style="align-self:flex-start"
          >一键起量
          <i
            class="iconfont icon-tishi"
            title="一键起量的效果预期
- 功能开启后的6小时内，系统会尽可能花完起量预算，帮助广告获取转化量，但由于系统激进探索，期间转化成本会偏高。
- 如在探索过程中，系统判断广告效果过差（竞争力本身过差/成本偏差过大），则会停止探索，防止预算浪费。
- 当探索预算花完后，广告恢复正常投放，系统会使用起量期间积累的转化数据来帮助广告平稳成本，继续获量。"
          ></i
        ></span>
        <el-switch v-model="autoAcquisitionSwitch"></el-switch>
      </div>
      <div class="build-settings-part" v-if="autoAcquisitionSwitch">
        <span style="align-self: flex-start;">
          探索预算
          <i
            class="iconfont icon-tishi"
            title="一键起量的效果预期
- 功能开启后的6小时内，系统会尽可能花完起量预算，帮助广告获取转化量，但由于系统激进探索，期间转化成本会偏高。
- 如在探索过程中，系统判断广告效果过差（竞争力本身过差/成本偏差过大），则会停止探索，防止预算浪费。
- 当探索预算花完后，广告恢复正常投放，系统会使用起量期间积累的转化数据来帮助广告平稳成本，继续获量。"
          ></i>
        </span>
        <div class="auto-acquisition-switch">
          <el-input-number
            v-model="autoAcquisitionBudget"
            placeholder=""
            :min="200"
            :max="100000"
          ></el-input-number>
          <p>探索预算范围：200元 ≤ 探索预算 ≤ 100000元</p>
          <p>
            1.一键起量会在6小时尽可能消耗完预算，期间产生的消耗不赔付，但转化计入赔付门槛判断
          </p>
          <p>
            2.一键起量可能导致转化成本高于预期，且起量结束后不一定能持续消耗
          </p>
        </div>
      </div>
    </div>
    <div class="pre-next-step">
      <el-button type="info" size="large" @click="$emit('update:curStep', 1)"
        >上一步</el-button
      >
      <el-button type="primary" size="large" @click="nextStep"
        >下一步</el-button
      >
    </div>
  </div>
</template>

<script>
import BScroll from 'better-scroll'
import { getBookList } from '@/api/account'
import { getClassifyList } from '@/api/classify'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'buildSetting',
  props: ['checkList', 'curStep'],
  data () {
    return {
      bookName: '', // 用于显示
      copyBook: '', // 创建一个书籍名称的副本 用于给接口传递书籍使用
      bookId: null, // 当前选中书籍的id
      options: [],
      projectName: '',
      topImageNum: [1],
      maxNumber: 1,
      projectNum: 1,
      minProjectAdNum: 1,
      maxProjectAdNum: 1,
      projectAdNum: 1,
      isChooseMore: false,
      model: false, // 是否开启自动扩量
      checkboxArr: [],
      dayBudget: 50, // 每日预算
      bid: 1, // 出价
      autoAcquisitionSwitch: false,
      autoAcquisitionBudget: 200,
      categoryList: [],
      categoryId: null,
      filterBookList: [],
      bookScroll: null,
      isShowFilterBook: false,
      isCanHidden: false,
      topImgsLen: 0
    }
  },
  watch: {
    topImageNum: {
      handler (newV) {
        const maxNumber = Math.max(...newV)
        this.maxNumber = maxNumber >= 6 ? 100 : maxNumber
        if (this.maxNumber < this.projectNum) {
          this.projectNum = this.maxNumber
        }
      }
    },
    projectAdNum: {
      handler (newV) {
        if (newV > this.firstStepData.crowdInfoList.length) {
          this.$message({
            showClose: true,
            message: '广告数量不能大于定向人群的数量!',
            type: 'error'
          })
        }
      }
    },
    categoryId: {
      handler (newV) {
        getBookList({
          pageSize: 99999,
          categoryId: newV === 0 ? null : newV
        }).then(res => {
          this.options = res.list
        })
      }
    },
    curStep: {
      handler (newV) {
        if (newV === 2) {
          const { directs } = this.firstStepData
          this.maxProjectAdNum = directs.length
        }
        if (newV === 1) {
          this.topImageNum = [1]
        }
      }
    },
    bookName: {
      handler (newV, oldV) {
        if (newV) {
          if (newV && oldV && newV.length < oldV.length) {
            this.isCanHidden = false
          }
          this.filterBookList = this.options.filter(item => {
            return item.bookName.includes(newV)
          })
          if (this.filterBookList.length > 0 && !this.isCanHidden) {
            this.isShowFilterBook = true
          } else {
            this.isShowFilterBook = false
          }
          this.initScroll()
        } else {
          this.isCanHidden = false
          this.isShowFilterBook = false
          this.filterBookList = []
        }
      }
    },
    isCanHidden: {
      handler (newV) {
        if (this.filterBookList.length > 0 && !newV) {
          this.isShowFilterBook = true
        } else {
          this.isShowFilterBook = false
        }
      }
    },
    copyBook: {
      handler (newV) {
        if (newV) {
          this.bookId = newV.id
          this.projectName = this.getDefaultProjectName(newV.bookName)
        }
      }
    }
  },
  computed: {
    ...mapState(['firstStepData'])
  },
  mounted () {
    this.initData()
  },
  methods: {
    ...mapMutations(['setSecondStepData']),
    initData () {
      getClassifyList({
        type: 3
      }).then(res => {
        this.categoryList = res
        this.categoryList.unshift({
          id: 0,
          name: '所有分类',
          status: 1,
          type: 3
        })
        getBookList({
          pageSize: 99999,
          categoryId: this.categoryId
        }).then(res => {
          this.options = res.list
          // this.options.unshift()
        })
      })
    },
    initScroll () {
      this.$nextTick(() => {
        // this.bookScroll =
        const wrapper = document.querySelector('.book-wrapper')
        if (!this.bookScroll) {
          this.bookScroll = new BScroll(wrapper, {
            scrollY: true,
            click: true,
            scrollbar: {
              fade: false,
              interactive: true
            },
            bounce: {
              top: true,
              bottom: true,
              left: true,
              right: true
            },
            bounceTime: '2000',
            mouseWheel: true
          })
        }
      })
    },
    handleChooseMore () {
      if (this.firstStepData.crowdInfoList.length === 1) {
        this.$message({
          showClose: true,
          message: '广告数量不能大于定向人群的数量!',
          type: 'error'
        })
      } else {
        this.isChooseMore = !this.isChooseMore
      }
    },
    chooseBookItem (bookItem) {
      this.bookName = bookItem.bookName
      this.copyBook = bookItem
      this.isCanHidden = true
    },
    handleIconClick () {
      this.$refs.input.select()
    },
    getDefaultProjectName (bookName) {
      const nowTime = new Date()
      const year = nowTime.getFullYear()
      const month =
        nowTime.getMonth() + 1 >= 10
          ? nowTime.getMonth() + 1
          : `0${nowTime.getMonth() + 1}`
      const days =
        nowTime.getDate() > 10 ? nowTime.getDate() : `0${nowTime.getDate()}`
      const hours =
        nowTime.getHours() > 10 ? nowTime.getHours() : `0${nowTime.getHours()}`
      const minutes =
        nowTime.getMinutes() > 10
          ? nowTime.getMinutes()
          : `0${nowTime.getMinutes()}`
      const seconds = nowTime.getSeconds()
      const milliseconds = nowTime.getMilliseconds()
      return `${bookName}-${year}${month}${days}${hours}${minutes}${seconds}_${milliseconds}`
    },
    checkDisabledStatus (num) {
      const cantPosTypeArr = [101, 8]
      return this.firstStepData.planTopImgs.length < num || cantPosTypeArr.includes(this.firstStepData.posType)
    },
    nextStep () {
      const {
        bid,
        dayBudget,
        bookId,
        projectName,
        topImageNum,
        projectNum,
        minProjectAdNum,
        autoAcquisitionSwitch,
        autoAcquisitionBudget,
        categoryId
      } = this

      if (!bookId) {
        this.$message({
          showClose: true,
          message: '请选择书籍!',
          type: 'error'
        })
        return
      }
      if (topImageNum.length === 0) {
        this.$message({
          showClose: true,
          message: '请勾选顶图数量!',
          type: 'error'
        })
        return
      }
      if (!projectName) {
        this.$message({
          showClose: true,
          message: '请输入计划名称!',
          type: 'error'
        })
        return
      }
      if (dayBudget < 50 || dayBudget > 40000000) {
        this.$message({
          showClose: true,
          message: '您的每日预算范围不符合系统要求，请检查后再输入!',
          type: 'error'
        })
        return
      }
      if (bid < 0.1 || bid > 5000) {
        this.$message({
          showClose: true,
          message: '您的出价范围不符合系统要求，请检查后再输入!',
          type: 'error'
        })
        return
      }
      let intgerBitsOf
      this.setSecondStepData({
        bid,
        dayBudget,
        bookId,
        cname: projectName,
        topImgStr: topImageNum.join(','),
        cNum: projectNum,
        cAdNum: minProjectAdNum,
        categoryId: categoryId === null ? 0 : categoryId,
        autoAcquisitionSwitch: !autoAcquisitionSwitch ? 0 : 1,
        autoAcquisitionBudget: !autoAcquisitionSwitch
          ? 0
          : Number(this.getNumVal(autoAcquisitionBudget))
      })
      this.$emit('update:curStep', 3)
    },
    getNumVal (val) {
      const interBits = `${val}`.split('.')[0]
      let decBits = `${val}`.split('.')[1] ? `${val}`.split('.')[1] : null
      if (decBits) {
        decBits = decBits.length < 2 ? `${decBits}0` : decBits
      }

      return `${interBits}${decBits || '00'}`
    }
  }
}
</script>
<style lang="scss" scoped>
.icon-tishi {
  color: #c7c7c7;
}
h3 {
  font-size: 14px;
  margin: 20px 20px 0;
}
.choose-book {
  position: relative;
  margin-left: 10px;
  .book-container {
    position: absolute;
    background: #fff;
    z-index: 1000;
    width: 300px;
    top: 40px;
    > p {
      padding: 12px 10px;
    }
    .book-wrapper {
      overflow: hidden;
      border: 1px solid #ccc;
      border-radius: 4px;
      .ul-book-wrapper {
        li {
          label {
            box-sizing: border-box;
            padding: 12px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #24262e;
          }
          &:hover {
            background: #ebedf0;
          }
        }
      }
    }
  }
}
.build-settings-container {
  background: #fff;
  margin: 0 10px 10px 16px;
  position: relative;
  .container {
    box-sizing: border-box;
    padding: 20px;
    .build-settings-part {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 25px 0;
      > span {
        min-width: 150px;
      }
      .part {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > span {
          width: 150px;
        }
      }
    }
  }
  .pre-next-step {
    position: absolute;
    bottom: -60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .auto-acquisition-switch {
    font-size: 12px;
    color: #8f9196;
    p {
      margin: 4px 0;
    }
  }
}
</style>
