<template>
  <div class="add-novel-plan">
    <div class="add-novel-plan-container">
      <div class="nav-point box-shadow-light-grey border-radius-6" v-show="curStep !== 3">
        <div class="novel-put-setting setting">
          <p style="font-weight:bold">投放时间</p>
          <p>
            <a
              href="javascript:void(0)"
              @click="scrollTarget(1, 'novel-plan-put-time')"

            >
              投放时间</a
            >
          </p>
          <p>
            <a
              href="javascript:void(0)"
              @click="scrollTarget(2, 'novel-plan-bidding-method')"

              >出价方式</a
            >
          </p>
        </div>
        <div class="novel-add-setting setting">
          <p style="font-weight:bold">广告设置</p>
          <p>
            <a
              href="javascript:void(0)"
              @click="scrollTarget(3, 'novel-plan-page')"

              >选择推广页</a
            >
          </p>
          <p>
            <a
              href="javascript:void(0)"
              @click="scrollTarget(4, 'novel-plan-setting-top')"

              >设置顶部图</a
            >
          </p>
          <p>
            <a
              href="javascript:void(0)"
              @click="scrollTarget(5, 'novel-plan-ad-setting')"

              >外层广告设置</a
            >
          </p>
        </div>
        <div class="novel-directional-setting setting">
          <p style="font-weight:bold">基础信息</p>
          <p>
            <a
              href="javascript:void(0)"
              @click="scrollTarget(6, 'novel-plan-directional-crowd')"

              >定向人群</a
            >
          </p>
          <p>
            <a
              href="javascript:void(0)"
              @click="scrollTarget(7, 'luoka-directional')"

              >罗卡定向</a
            >
          </p>
        </div>
        <div class="novel-build-settings setting">
          <p style="font-weight:bold">生成设置</p>
          <p>计划详情</p>
        </div>
      </div>
      <combination-settings
        :curStep.sync="curStep"
        :posType="posType"
        :isActive.sync="isActive"
        :isClick.sync="isClick"
        v-show="curStep === 1"
        :checkList="checkList"
        @goBack="handlGoBack"
      ></combination-settings>
      <build-settings
        style="flex-grow:1"
        :checkList="checkList"
        :posType="posType"
        :curStep.sync="curStep"
        v-show="curStep === 2"
      ></build-settings>
      <plan-list
        style="width:100%"
        :checkList="checkList"
        :posType="posType"
        v-if="curStep === 3"
        :curStep.sync="curStep"
        @closeDrawer="$emit('closeDrawer')"
      >
      </plan-list>
    </div>
  </div>
</template>

<script>
import combinationSettings from './children/combination-settings'
import buildSettings from './children/build-settings'
import planList from './children/plan-list'
import { mapState, mapMutations } from 'vuex'
// import propertyToChinese from './../scripts/propertyToChinese'

export default {
  props: ['posType', 'showDrawer'],
  data () {
    return {
      curStep: 1,
      checkList: ['朋友圈信息流'],
      typeStyle: {
        cursor: 'pointer'
      },
      isActive: 1,
      isActiveEctype: 1,
      isClick: false
    }
  },
  computed: {
    ...mapState([
      'firstStepData',
      'secondStepData',
      'thirdStepData',
      'isProjectSaved'
    ])
  },
  watch: {
    curStep: {
      handler (newV) {
        if (newV !== 1) {
          this.typeStyle = {
            cursor: 'default'
          }
        } else {
          this.typeStyle = {
            cursor: 'pointer'
          }
        }
      }
    },
    isActive: {
      handler (newV) {
        this.isActiveEctype = newV
      }
    }
  },
  methods: {
    ...mapMutations(['setFirstStepData']),
    handleChange (value) {
      value.length > 1 && value.shift()
    },
    // 跳转到制定位置
    scrollTarget (num, id) {
      document.querySelector(`#${id}`).scrollIntoView({
        behavior: 'smooth'
      })
      this.isClick = true
      this.$nextTick(() => {
        this.isActive = num
        this.isActiveEctype = num
      })
    },
    handlGoBack () {
      this.$emit('goBack')
    }
  },
  beforeDestroy () {
    window.localStorage.removeItem('notInActionList')
  },
  components: {
    combinationSettings,
    buildSettings,
    planList
  }
}
</script>
<style lang="scss" scoped>
a {
  outline: none;
  text-decoration: none;
  color: #000;
  border-bottom: 2px solid #fff;
}
.is-active {
  border-bottom-color: #00bf8a !important;
  color:#00bf8a !important;
}
.change-steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 20px;
}
.add-novel-plan {
  font-size: 14px;
  .add-novel-plan-header {
    background: #fff;
    margin: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div:nth-of-type(1) {
      padding-left: 20px;
      padding-right: 20px;
      border-right: 1px solid #ccc;
    }
    > div:nth-of-type(2) {
      margin-left: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .choose-type {
        display: flex;
        min-height: 84px;
        .type {
          position: relative;
          margin: 10px 10px 10px 0;
          padding: 0 10px;
          max-width: 450px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border: 1px solid #ccc;
          border-radius: 4px;
          cursor: pointer;
          color: #1f1f1f;
          i {
            position: absolute;
            top: 8%;
            left: 95%;
            font-size: 14px;
            color: #b2b2b2;
            display: none;
          }
          .hover-class {
            display: block;
            color: #00bf8a;
          }
          &:hover {
            i {
              display: block;
            }
          }
        }
        .selected-active {
          color: #00bf8a !important;
          border: 1px solid #00bf8a;
          background: #fafefc;
        }
      }
    }
  }
  .add-novel-plan-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    // margin: 10px 0 10px 10px;
    .nav-point {
      width: 150px;
      min-width: 150px;
      background: #fff;
      position: sticky;
      top: 0;
      .setting {
        padding: 20px;
        border-bottom: 1px solid #f5f5f5;
        p {
          margin-bottom: 15px;
          color: #5a5a5a;
        }
        > p:nth-of-type(2) {
        }
        > p:not(:nth-of-type(1)) {
          font-size: 14px;
          margin-left: 20px;
          color: #000;
        }
      }
    }
  }
}
</style>
