<template>
  <div class="plan-list">
    <div class="plan-list-cantainer border-radius-6 box-shadow-light-grey">
      <div v-if="firstStepData.posType === 999">
        <h3>朋友圈列表</h3>
        <div
          style="display:flex;justify-content:flex-start;align-items:center;flex-wrap:wrap"
        >
          <div
            class="friend-circle-preview"
            v-for="(pItem, index) in friendCircleList"
            :key="index"
          >
            <div class="top-info">
              <div>
                <img :src="pItem.adImage" alt="" />
                <div>
                  <p style="color:#576b95">
                    <span>{{ pItem.channelName }}</span>
                    <span class="tag"
                      >广告 <i class="el-icon-arrow-down"></i
                    ></span>
                  </p>
                  <div
                    class="promoted-text"
                    ref="promotedText"
                    style="font-size:12px"
                    v-html="pItem.promotedText"
                  ></div>
                </div>
              </div>
            </div>
            <div
              class="ad-image"
              :style="getAdImageStyle(pItem.imageList.length)"
            >
              <div class="image-list">
                <div
                  class="image-item"
                  v-for="(image, index2) in pItem.imageList"
                  :key="index2"
                  :style="getImageStyle(pItem.imageList.length)"
                >
                  <img :src="image" alt="" />
                </div>
              </div>
              <p>了解公众号 <i class="iconfont icon-lianjie2"></i></p>
              <p>2 分钟前</p>
            </div>
            <div class="mask-hover">
              <div>
                <i class="el-icon-edit" @click="checkDetail"></i>
                <i
                  class="el-icon-refresh"
                  title="刷新当前效果"
                  @click="refreshImage(pItem)"
                ></i>
                <i class="el-icon-delete" @click="deleteItem(1, index)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="firstStepData.posType === 101" class="channel-part">
        <h3>优雅横版大图</h3>
        <!-- v-for -->
        <div class="channel-part-container">
          <div
            class="channel-account-preview"
            v-for="n in firstStepData.planGroup.desc.length"
            :key="n"
          >
            <div class="placeholder-image">
              <img :src="firstStepData.planGroup.desc[n - 1].imgUrl" alt="" />
            </div>
            <div class="channel-account-footer">
              <div>
                <div class="channel-name-info">
                  <img
                    src="http://wx.qlogo.cn/mmhead/Q3auHgzwzM4mTGQic9geC7158gERWh2gMxfbfDy0eDHDQEyCQ11ibicYg/0"
                    alt=""
                  />
                  <span style="margin-left:6px"
                    ><span>广告主名称</span>
                    <template v-if="firstStepData.planGroup.mpTagType === 4">
                      <br /><span
                        style="font-size:12px;color:#ccc;margin-top:5px"
                        >xx篇原创文章 xx位好友关注</span
                      >
                    </template>
                    <template
                      v-else-if="firstStepData.planGroup.mpTagType === 8"
                    >
                      <br />
                      <span style="font-size:12px;color:#ccc;margin-top:5px"
                        >最近已有xxx人关注</span
                      >
                    </template>
                  </span>
                </div>
                <div>
                  <span>{{ firstStepData.planGroup.buttonParam }}</span>
                </div>
              </div>
            </div>
            <div class="ad-tags">
              <span>
                广告
              </span>
            </div>
            <div class="mask">
              <i class="el-icon-edit" @click="checkDetail"></i>
              <!-- <i class="iconfont icon-lajitong" @click="deleteItem(2)"></i> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="firstStepData.posType === 8" class="empty-tips">
        <span>激励模式暂无预览效果</span>
      </div>
      <div v-else-if="firstStepData.posType === 0" class="channel-part">
        <h3>公众号底部文案</h3>
        <div class="channel-part-container">
          <div
            class="channel-account-preview"
            v-for="n in firstStepData.planGroup.desc.length"
            :key="n"
          >
            <div class="placeholder-image">
              <img :src="firstStepData.planGroup.desc[n - 1]" alt="" />
            </div>
            <div class="channel-account-footer">
              <div>
                <div class="channel-name-info">
                  <template v-if="firstStepData.planGroup.headDescType === 1">
                    <img
                      src="http://wx.qlogo.cn/mmhead/Q3auHgzwzM4mTGQic9geC7158gERWh2gMxfbfDy0eDHDQEyCQ11ibicYg/0"
                      alt=""
                    />
                    <span style="margin-left:6px">广告主名称</span>
                  </template>
                  <template v-else>
                    <div>
                      {{ firstStepData.planGroup.headDescription }}
                    </div>
                  </template>
                </div>
                <div>
                  <span>{{ firstStepData.planGroup.buttonParam }}</span>
                </div>
              </div>
            </div>
            <div class="ad-tags">
              <span>
                广告
              </span>
            </div>
            <div class="mask">
              <i class="el-icon-edit" @click="checkDetail"></i>
              <!-- <i class="iconfont icon-lajitong" @click="deleteItem(2)"></i> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pre-next-step">
      <el-button
        type="info"
        style="margin-right:10px"
        size="large"
        @click="$emit('update:curStep', 2)"
        >上一步</el-button
      >
      <div>
        <el-button
          type="primary"
          size="large"
          @click="handleSave"
          :loading="saveLoading"
          >保存</el-button
        >
        <el-button type="primary" size="large" @click="nextStep"
          >保存并推送到公众号</el-button
        >
      </div>
    </div>
    <el-drawer
      title="添加公众号和自定义人群包"
      size="90%"
      append-to-body
      :visible.sync="isShowDialog"
      :before-close="handleClose"
    >
      <div class="main-container">
        <div class="choose-channelName">
          <el-select
            v-model="channelNameList"
            filterable
            multiple
            placeholder="请选择公众号"
            size="medium"
          >
            <el-option
              v-for="item in options"
              :label="item.mpName"
              :value="item.appid"
              :key="item.appid"
            >
            </el-option>
          </el-select>

          <el-button
            @click="resetData(false)"
            type="info"
            plain
            style="margin-left:10px"
            >重置</el-button
          >
        </div>
        <div class="channel-info">
          <el-table :data="chooseChannelList" style="width:100%" height="700px">
            <el-table-column label="头像" width="70">
              <template slot-scope="scope">
                <img :src="scope.row.headUrl" alt="头像" />
              </template>
            </el-table-column>
            <el-table-column
              label="公众号名称"
              prop="mpName"
              width="100"
            ></el-table-column>
            <el-table-column label="自定义人群">
              <el-table-column label="开关" width="70">
                <template slot-scope="scope">
                  <div>
                    <span style="vertical-align:middle;margin-right:5px"></span
                    ><el-switch v-model="scope.row.isDmpAudience"></el-switch>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="定向人群" width="200">
                <template slot-scope="scope">
                  <div class="choose-crowd crowd">
                    <div
                      class="choose-wrapper wrapper"
                      v-show="scope.row.isDmpAudience"
                    >
                      <div>
                        <span>定向人群</span>
                        <el-button
                          plain
                          size="mini"
                          class='button-small'
                          @click="chooseCrowd(scope.row)"
                          >选择人群</el-button
                        >
                      </div>
                      <div
                        class="choose-wrapper-list wrapper-list"
                        v-show="scope.row.crowdPackage.length > 0"
                      >
                        <div
                          class="choose-wrapper-item wrapper-item"
                          v-for="(item, index) in scope.row.crowdPackage"
                          :key="index"
                        >
                          <span>{{ item.name }}</span>
                          <i
                            class="iconfont icon-shanchu1"
                            style="cursor:pointer"
                            @click="delCrowd(scope.row, item, index, 1)"
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div v-show="!scope.row.isDmpAudience">
                      <span style="color:#ccc">请勾自定义人群</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="排除人群">
                <template slot-scope="scope">
                  <div
                    class="eliminate-crowd crowd"
                    v-show="scope.row.isDmpAudience"
                  >
                    <div class="eliminate-wrapper wrapper">
                      <div>
                        <span>排除人群</span>
                        <el-button
                          plain
                          size="mini"
                          class='button-small'
                          @click="handleEliminateCrowd(scope.row)"
                          >选择人群</el-button
                        >
                      </div>
                      <div
                        class="eliminate-wrapper-list wrapper-list"
                        v-show="scope.row.eliminateCrowd.length > 0"
                      >
                        <div
                          class="choose-wrapper-item wrapper-item"
                          v-for="(item, index) in scope.row.eliminateCrowd"
                          :key="index"
                        >
                          <span>{{ item.name }}</span>
                          <i
                            class="iconfont icon-shanchu1"
                            style="cursor:pointer"
                            @click="delCrowd(scope.row, item, index, 2)"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="!scope.row.isDmpAudience">
                    <span style="color:#ccc">请勾选自定义人群</span>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="种子人群">
              <el-table-column label="开关" width="150">
                <div slot="header">
                  <span
                    >开关
                    <i
                      class="iconfont icon-tishi"
                      title="在已选择广告定向外寻找更多合适的目标用户。仅支持智能优化出价方式。"
                    ></i
                  ></span>
                </div>
                <template slot-scope="scope">
                  <div class="auto-form-label">
                    <span style="vertical-align: middle;margin-right:5px;"
                      >自动扩量
                    </span>
                    <el-switch v-model="scope.row.autoForm"></el-switch>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="不可突破定向">
                <div slot="header">
                  <span
                    >不可突破定向
                    <i
                      class="iconfont icon-tishi"
                      style="margin-right:10px;"
                      title='广告将突破已设置的各维度定向，寻找合适的用户。在此基础上，「地域、年龄、性别」等都分维度可设定为不可突破， 请根据需要合理勾选使用！如果定向人群中"性别"栏位有一项为“全部”则不可勾选“性别”'
                    ></i>
                  </span>
                </div>
                <template slot-scope="scope">
                  <div
                    v-if="scope.row.autoForm"
                    style="margin-top:5px;margin-left:20px;display:flex;align-items:center"
                  >
                    <el-checkbox-group v-model="scope.row.checkboxArr">
                      <el-checkbox label="area">地域</el-checkbox>
                      <el-checkbox label="age">年龄</el-checkbox>
                      <el-checkbox
                        label="gender"
                        :disabled="
                          firstStepData.directs.findIndex(
                            item => JSON.parse(item.gender).length === 0
                          ) > -1
                        "
                        >性别</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                  <div v-else>
                    <span style="color:#ccc">请勾选自动扩量</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="种子人群">
                <template slot-scope="scope">
                  <div class="feed-crowd crowd" v-show="scope.row.autoForm">
                    <div class="feed-wrapper wrapper">
                      <div>
                        <span>选择种子人群</span>
                        <el-button
                          plain
                          size="mini"
                          class='button-small'
                          @click="handleFeedCrowd(scope.row)"
                          >选择人群</el-button
                        >
                      </div>
                      <div
                        class="feed-wrapper-list wrapper-list"
                        v-show="scope.row.feedCrowd.length > 0"
                      >
                        <div
                          class="choose-wrapper-item wrapper-item"
                          v-for="(item, index) in scope.row.chooseFeedCrowd"
                          :key="index"
                        >
                          <span>{{ item.name }}</span>
                          <i
                            class="iconfont icon-shanchu1"
                            style="cursor:pointer"
                            @click="delCrowd(scope.row, item, index, 3)"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="!scope.row.autoForm">
                    <span style="color:#ccc">请勾选自动扩量</span>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  class='button-small'
                  style="margin-left:5px"
                  @click="delCrowdItem(scope.row)"
                  size="mini"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="cancel-or-confirm">
          <el-button type="info" size="large" @click="cancel">取消</el-button>
          <el-button
            type="primary"
            size="large"
            @click="handleOk"
            :loading="isLoading"
            >确认</el-button
          >
        </div>
      </div>
    </el-drawer>
    <choose-crowdList
      v-if="isShowCrowdPackage && (type === 1 || type === 2)"
      :crowdInfo="crowdInfo"
      :isShowCrowdPackage.sync="isShowCrowdPackage"
      :mpName="mpName"
      :chooseChannelList="chooseChannelList"
      :curIndex="curIndex"
      :echoList="echoList"
      :type="type"
      @success="handleSuccess"
    ></choose-crowdList>
    <choose-feedCrowd
      v-if="isShowFeedCrowd"
      :crowdInfo="feedCrowd"
      :isShowFeedCrowd.sync="isShowFeedCrowd"
      :mpName="mpName"
      :chooseChannelList="chooseChannelList"
      :curIndex="curIndex"
      :echoList="feedEchoList"
      @success="handleFeedSuccess"
    >
    </choose-feedCrowd>
  </div>
</template>
<script>
// import BScroll from 'better-scroll'
import chooseCrowdList from './choose-crowd'
import chooseFeedCrowd from './choose-feed-crowd'
import {
  // getPushAppIdList,
  getUserChannelList,
  getWechatRegionCrowd,
  // getWeChatRegion,
  addWeChatPlan,
  pushAppIdCampaign
} from '@/api/novelPut'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'planList',
  props: ['checkList', 'curStep'],
  data () {
    return {
      defaultActive: '1',
      type: null, // 用于区分是排除人群的操作还是定向人群的操作(定向：1; 排除2)
      mpName: '',
      search: '',
      scroll: null,
      promotedText: '这是自定义的广告文案',
      friendCircleList: [],
      isShowDialog: false,
      isShowCrowdPackage: false,
      isShowFeedCrowd: false,
      channelNameList: [],
      options: [],
      chooseChannelList: [], // 已选择的公众号
      crowdInfo: [],
      crowdPackage: [],
      eliminateCrowd: [],
      feedCrowd: [], // 种子人群
      curIndex: null,
      rightDefaultChecked: [], // 用于穿梭框回显的操作
      leftDefaultChecked: [],
      echoList: [], // 回显定向人群和排除人群
      feedEchoList: [], // 回显种子人群
      planId: null, // 计划id
      checkboxArr: [],
      topImagesArr: [],
      isLoading: false,
      maxShowImageNum: 20, // 需要展示的效果数量
      topImags: [],
      editorList: [],
      composePic: [],
      saveLoading: false,
      putLoading: false
    }
  },
  computed: {
    ...mapState([
      'firstStepData',
      'secondStepData',
      'channelName',
      'isProjectSaved'
    ]),
    isDmpAudience () {
      return this.chooseChannelList.map(item => item.isDmpAudience)
    },
    autoForm () {
      return this.chooseChannelList.map(item => item.autoForm)
    }
  },
  watch: {
    isShowDialog: {
      handler (newV) {
        if (newV) {
          this.getChannelList()
        }
      }
    },
    channelNameList: {
      handler (newV, oldV) {
        if (Array.isArray(newV)) {
          const list = JSON.parse(
            JSON.stringify(
              this.options.filter(channel =>
                newV.some(item => item === channel.appid)
              )
            )
          )
          // 如果是添加公众号的操作
          if (newV.length > oldV.length) {
            list.forEach(item => {
              if (
                !this.chooseChannelList.find(
                  channel => channel.appid === item.appid
                )
              ) {
                this.chooseChannelList.push(item)
              }
            })
          }
          // 如果是删除公众号的操作
          if (newV.length < oldV.length) {
            const differAppIdItem = oldV.find(item => !newV.includes(item))
            const targetIndex = this.chooseChannelList.findIndex(
              channel => channel.appid === differAppIdItem
            )
            if (targetIndex > -1) {
              this.chooseChannelList.splice(targetIndex, 1)
            }
          }
        }
      }
    },
    rightDefaultChecked: {
      handler (newV) {}
    },
    curIndex: {
      handler (newV) {}
    },
    // !通过isDmpAudience来判断表格的哪一行变为了false
    isDmpAudience: {
      handler (newV) {
        newV.forEach((item, index) => {
          // !如果变为了false 则需要重置已选择的定向人群和排除人群
          if (!item) {
            this.chooseChannelList[index].crowdInfo = []
            this.chooseChannelList[index].crowdPackage = []
            this.chooseChannelList[index].eliminateCrowd = []
          }
        })
      }
    },
    autoForm: {
      handler (newV) {
        newV.forEach((item, index) => {
          if (!item) {
            this.chooseChannelList[index].feedCrowd = []
            this.chooseChannelList[index].chooseFeedCrowd = []
            this.chooseChannelList[index].checkboxArr = []
          }
        })
      }
    }
  },
  mounted () {
    this.renderFriendCircle()
  },
  methods: {
    ...mapMutations([
      'setIsProjectSaved',
      'setEchoNovelPlanInfo',
      'resetFirstStepData',
      'resetSecondStepData'
    ]),
    initBScroll () {},
    refreshImage (pItem) {
      const imageLen = pItem.imageList.length
      const imageList = []
      // for (let i = 0; i < imageLen; i++) {
      //   imageList.push(
      //     this.topImgs[Math.floor(Math.random() * this.topImgs.length)].topImg
      //   );
      // }

      const topImgs = JSON.parse(JSON.stringify(this.topImgs))
      this.recursionImageList(topImgs, imageList, imageLen)
      pItem.imageList = imageList
      pItem.promotedText = this.editorList[Math.floor(Math.random() * (this.editorList.length))].html
    },
    renderFriendCircle () {
      const friendCircleList = []
      const { planTopImgs } = this.firstStepData
      this.topImgs = planTopImgs
      // 将topImagesArr从小到大排序
      this.topImagesArr = this.secondStepData.topImgStr
        .split(',')
        .map(item => Number(item))
        .sort((a, b) => a - b)
      if (this.firstStepData.posType === 999) {
        this.editorList = this.firstStepData.editorList
        // for()
        // !判断顶图数量

        switch (this.topImgs.length) {
          case 1:
          case 2:
            // ! 判断勾选第二部计划中顶图数量的情况
            for (let i = 0; i < this.topImagesArr.length; i++) {
              const val = this.topImagesArr[i]
              const imageList = []
              // for (let j = 0; j < val; j++) {
              //   const index = Math.ceil(
              //     Math.random() * this.topImgs.length - 1
              //   );
              //   imageList.push(this.topImgs[index].topImg);
              // }
              const topImgs = JSON.parse(JSON.stringify(this.topImgs))
              this.recursionImageList(topImgs, imageList, val)
              friendCircleList.push({
                adImage:
                  'http://wx.qlogo.cn/mmhead/Q3auHgzwzM4mTGQic9geC7158gERWh2gMxfbfDy0eDHDQEyCQ11ibicYg/0',
                channelName: '广告主名称',
                // promotedText: this.editorList[0].html,
                promotedText: this.editorList[Math.floor(Math.random() * (this.editorList.length))].html,
                imageList: imageList
              })
            }
            this.friendCircleList = friendCircleList
            break
          case 3:
          case 4:
          case 5:
          case 6:
            if (this.topImagesArr.length === 1) {
              this.renderOnlyOneTopImages()
            } else if (this.topImagesArr.length === 2) {
              this.renderOnlyTwoTopImages()
            } else {
              this.renderMoreTopImages()
            }
            break
        }
      }
    },
    renderOnlyOneTopImages () {
      const friendCircleList = []
      if (this.topImagesArr[0] === 1) {
        for (let i = 0; i < this.topImgs.length; i++) {
          const imageList = [this.topImgs[i].topImg]
          friendCircleList.push({
            imageList
          })
        }
        // let topImgs = JSON.parse(JSON.stringify(this.topImgs))
      } else {
        for (let i = 0; i < this.maxShowImageNum; i++) {
          const imageList = []
          // for (let j = 0; j < this.topImagesArr[0]; j++) {
          //   const index = Math.floor(Math.random() * this.topImgs.length);
          //   imageList.push(this.topImgs[index].topImg);
          // }
          const topImgs = JSON.parse(JSON.stringify(this.topImgs))
          this.recursionImageList(topImgs, imageList, this.topImagesArr[0])
          friendCircleList.push({
            imageList
          })
        }
      }

      friendCircleList.forEach(item => {
        const tIndex = Math.floor(Math.random() * (this.editorList.length))
        item.adImage =
          'http://wx.qlogo.cn/mmhead/Q3auHgzwzM4mTGQic9geC7158gERWh2gMxfbfDy0eDHDQEyCQ11ibicYg/0'
        item.channelName = '广告主名称'
        item.promotedText = this.editorList[tIndex].html
      })
      this.friendCircleList = friendCircleList.sort(
        (a, b) => a.imageList.length - b.imageList.length
      )
    },
    renderOnlyTwoTopImages () {
      const friendCircleList = []
      const isIncludesNumOne = this.topImagesArr.includes(1)
      if (isIncludesNumOne) {
        // 顶图数量部位不包含6 则总的种类数量一共不到20种 需要做区分
        const needShowImages = this.topImagesArr.includes(6)
          ? this.maxShowImageNum
          : this.topImagesArr.length + Math.pow(2, this.topImagesArr[1])
        for (let i = 0; i < needShowImages; i++) {
          const imageList = []
          if (i === 0) {
            friendCircleList.push({
              imageList: [this.topImgs[0].topImg]
            })
          } else if (i === 1) {
            friendCircleList.push({
              imageList: [this.topImgs[1].topImg]
            })
          } else {
            // for (let j = 0; j < this.topImagesArr[1]; j++) {
            //   const index = Math.floor(Math.random() * this.topImgs.length);
            //   imageList.push(this.topImgs[index].topImg);
            // }
            const topImgs = JSON.parse(JSON.stringify(this.topImgs))
            this.recursionImageList(topImgs, imageList, this.topImagesArr[1])
            friendCircleList.push({
              imageList
            })
          }
        }
      } else {
        // 数值小的展示8种，数值大的展示12种
        const divideNum = 8
        for (let i = 0; i < this.maxShowImageNum; i++) {
          const imageList = []
          if (i < divideNum) {
            // for (let j = 0; j < this.topImagesArr[0]; j++) {
            //   const index = Math.floor(Math.random() * this.topImgs.length);
            //   imageList.push(this.topImgs[index].topImg);
            // }
            const topImgs = JSON.parse(JSON.stringify(this.topImgs))
            this.recursionImageList(topImgs, imageList, this.topImagesArr[0])
          } else {
            // for (let j = 0; j < this.topImagesArr[1]; j++) {
            //   const index = Math.floor(Math.random() * this.topImgs.length);
            //   imageList.push(this.topImgs[index].topImg);
            // }
            const topImgs = JSON.parse(JSON.stringify(this.topImgs))
            this.recursionImageList(topImgs, imageList, this.topImagesArr[1])
          }
          friendCircleList.push({
            imageList
          })
        }
      }
      friendCircleList.forEach(item => {
        item.adImage =
          'http://wx.qlogo.cn/mmhead/Q3auHgzwzM4mTGQic9geC7158gERWh2gMxfbfDy0eDHDQEyCQ11ibicYg/0'
        item.channelName = '广告主名称'
        item.promotedText = this.editorList[Math.floor(Math.random() * (this.editorList.length))].html
      })
      this.friendCircleList = friendCircleList.sort(
        (a, b) => a.imageList.length - b.imageList.length
      )
    },
    renderMoreTopImages () {
      const friendCircleList = []
      if (this.topImagesArr.length === 3) {
        const divideSmall = 4
        const divideMiddle = 6
        for (let i = 0; i < this.maxShowImageNum; i++) {
          const imageList = []
          if (i < divideSmall) {
            // for (let j = 0; j < this.topImagesArr[0]; j++) {
            //   const index = Math.floor(Math.random() * this.topImgs.length);
            //   imageList.push(this.topImgs[index].topImg);
            // }
            const topImgs = JSON.parse(JSON.stringify(this.topImgs))
            this.recursionImageList(topImgs, imageList, this.topImagesArr[0])
          } else if (i >= divideSmall && i < divideSmall + divideMiddle) {
            // for (let j = 0; j < this.topImagesArr[1]; j++) {
            //   const index = Math.floor(Math.random() * this.topImgs.length);
            //   imageList.push(this.topImgs[index].topImg);
            // }
            const topImgs = JSON.parse(JSON.stringify(this.topImgs))
            this.recursionImageList(topImgs, imageList, this.topImagesArr[1])
          } else {
            // for (let j = 0; j < this.topImagesArr[2]; j++) {
            //   const index = Math.floor(Math.random() * this.topImgs.length);
            //   imageList.push(this.topImgs[index].topImg);
            // }
            const topImgs = JSON.parse(JSON.stringify(this.topImgs))
            this.recursionImageList(topImgs, imageList, this.topImagesArr[2])
          }
          friendCircleList.push({
            imageList
          })
        }
      }
      if (this.topImagesArr.length === 4) {
        const divideMini = 2
        const divideSmall = 4
        const divideMiddle = 6
        for (let i = 0; i < this.maxShowImageNum; i++) {
          const imageList = []
          if (i < divideMini) {
            // for (let j = 0; j < this.topImagesArr[0]; j++) {
            //   const index = Math.floor(Math.random() * this.topImgs.length);
            //   imageList.push(this.topImgs[index].topImg);
            // }
            const topImgs = JSON.parse(JSON.stringify(this.topImgs))
            this.recursionImageList(topImgs, imageList, this.topImagesArr[0])
          } else if (i >= divideMini && i < divideMini + divideSmall) {
            // for (let j = 0; j < this.topImagesArr[1]; j++) {
            //   const index = Math.floor(Math.random() * this.topImgs.length);
            //   imageList.push(this.topImgs[index].topImg);
            // }
            const topImgs = JSON.parse(JSON.stringify(this.topImgs))
            this.recursionImageList(topImgs, imageList, this.topImagesArr[1])
          } else if (
            i >= divideMini + divideSmall &&
            i < divideMini + divideSmall + divideMiddle
          ) {
            // for (let j = 0; j < this.topImagesArr[2]; j++) {
            //   const index = Math.floor(Math.random() * this.topImgs.length);
            //   imageList.push(this.topImgs[index].topImg);
            // }
            const topImgs = JSON.parse(JSON.stringify(this.topImgs))
            this.recursionImageList(topImgs, imageList, this.topImagesArr[2])
          } else {
            // for (let j = 0; j < this.topImagesArr[3]; j++) {
            //   const index = Math.floor(Math.random() * this.topImgs.length);
            //   imageList.push(this.topImgs[index].topImg);
            // }
            const topImgs = JSON.parse(JSON.stringify(this.topImgs))
            this.recursionImageList(topImgs, imageList, this.topImagesArr[3])
          }
          friendCircleList.push({
            imageList
          })
        }
      }
      friendCircleList.forEach(item => {
        item.adImage =
          'http://wx.qlogo.cn/mmhead/Q3auHgzwzM4mTGQic9geC7158gERWh2gMxfbfDy0eDHDQEyCQ11ibicYg/0'
        item.channelName = '广告主名称'
        item.promotedText = this.editorList[Math.floor(Math.random() * (this.editorList.length))].html
      })
      this.friendCircleList = friendCircleList.sort(
        (a, b) => a.imageList.length - b.imageList.length
      )
    },
    getChannelList () {
      getUserChannelList().then(res => {
        res.forEach(item => {
          item.headUrl = `${item.headUrl}/0`
          item.isDmpAudience = false
          item.crowdInfo = [] // 公众号的人群包信息
          item.crowdPackage = [] // 已选择的人群包合集
          item.eliminateCrowd = [] // 已排除的人群包信息
          item.feedCrowd = [] // 种子人群
          item.chooseFeedCrowd = [] // 已选择的种子人群
          item.autoForm = false
          item.checkboxArr = []
          // item.feedCrowd = res.filter(item=>item.type==='CUSTOMER_FILE'); // 种子人群
        })
        this.options = res
      })
    },
    checkDetail () {
      this.$emit('update:curStep', 1)
    },

    deleteItem (type, index) {
      if (type === 1) {
        this.friendCircleList.splice(index, 1)
        // this.topImagesArr.splice(index, 1);
      }
    },
    // 删除单个人群包
    delCrowd (row, item, index, type) {
      const { appid } = row
      this.curIndex = this.chooseChannelList.findIndex(
        channel => channel.appid === appid
      )

      if (type === 1) {
        this.chooseChannelList[this.curIndex].crowdPackage.splice(index, 1)
        this.echoList.splice(index, 1)
      } else if (type === 2) {
        this.chooseChannelList[this.curIndex].eliminateCrowd.splice(index, 1)
        this.echoList.splice(index, 1)
      }
    },
    // 删除选择的公众号
    delCrowdItem (row) {
      const { appid } = row
      const targetIndex = this.chooseChannelList.findIndex(
        channel => channel.appid === appid
      )
      this.chooseChannelList.splice(targetIndex, 1)
      this.channelNameList.splice(
        this.channelNameList.findIndex(item => item === appid),
        1
      )
      // this.chooseChannelList
    },
    getImageStyle (imageLen) {
      switch (imageLen) {
        case 1:
          return {
            width: '65%',
            height: '55%'
          }
        case 3:
          return {
            width: '22%',
            height: '22%'
          }
        case 4:
          return {
            width: '28%',
            height: '28%'
          }
        case 6:
          return {
            width: '25%',
            height: '25%'
          }
      }
    },
    getAdImageStyle (imageLen) {
      switch (imageLen) {
        case 4:
          return {
            width: '240px'
          }
      }
    },
    // 选择人群
    chooseCrowd (row) {
      this.type = 1
      const { appid, mpName } = row
      this.curIndex = this.chooseChannelList.findIndex(
        channel => channel.appid === appid
      )
      this.mpName = mpName
      getWechatRegionCrowd({ appid }).then(res => {
        res.forEach(item => {
          item.label = item.name
          item.key = item.audience_id
        })
        this.chooseChannelList[this.curIndex].crowdInfo = res
        // this.chooseChannelList[this.curIndex].feedCrowd =
        this.crowdInfo = res
        // 设置种子人群
        this.feedCrowd = res.filter(item => item.type === 'CUSTOMER_FILE')
        // 设置回显人群
        this.echoList = this.chooseChannelList[this.curIndex].crowdPackage
        this.isShowCrowdPackage = true
      })
    },
    // 排除人群
    handleEliminateCrowd (row) {
      this.type = 2
      const { appid, mpName } = row
      this.curIndex = this.chooseChannelList.findIndex(
        channel => channel.appid === row.appid
      )
      this.mpName = mpName
      getWechatRegionCrowd({ appid }).then(res => {
        res.forEach(item => {
          item.label = item.name
          item.key = item.audience_id
        })
        // 设置种子人群
        this.feedCrowd = res.filter(item => item.type === 'CUSTOMER_FILE')
        const copyRes = this.deepClone(res)
        const otherCopyRes = this.deepClone(copyRes)
        this.chooseChannelList[this.curIndex].crowdInfo = copyRes

        this.echoList = this.chooseChannelList[this.curIndex].eliminateCrowd
        this.crowdInfo = otherCopyRes
        this.isShowCrowdPackage = true
      })
    },
    handleSave () {
      this.saveLoading = true
      // this.getParams()
      addWeChatPlan(this.getParams())
        .then(() => {
          this.setIsProjectSaved(true)
          this.setEchoNovelPlanInfo({})
          this.isShowDialog = false
          this.isShowCrowdPackage = false
          this.isShowFeedCrowd = false
          this.$emit('closeDrawer')
        })
        .finally(() => {
          this.saveLoading = false
        })
    },
    handleClose () {
      this.isShowDialog = false
    },
    handleTransferChange (data, direction) {
      if (this.type === 1) {
        this.crowdPackage = data
      } else if (this.type === 2) {
        this.eliminateCrowd = data
      }
    },
    handleCancel () {
      this.crowdPackage = []
      this.crowdInfo = []
      this.eliminateCrowd = []
      this.feedCrowd = []
      this.isShowCrowdPackage = false
    },
    handleConfirm () {
      if (this.type === 1) {
        this.chooseChannelList[this.curIndex].crowdPackage = this.crowdPackage
      } else if (this.type === 2) {
        this.chooseChannelList[
          this.curIndex
        ].eliminateCrowd = this.eliminateCrowd
      }
      this.isShowCrowdPackage = false
      this.crowdInfo = []
      this.crowdPackage = []
      this.eliminateCrowd = []
      this.feedCrowd = []
    },
    getName (row, id) {
      return row.crowdInfo.find(crowd => crowd.audience_id === id).label
    },
    cancel () {
      this.resetData()
      this.isShowDialog = false
    },
    handleOk () {
      if (this.chooseChannelList.length === 0) {
        this.$message({
          showClose: true,
          message: '请选择推送的公众号!',
          type: 'error'
        })
        return
      }
      this.isLoading = true
      addWeChatPlan(this.getParams())
        .then(res => {
          this.setIsProjectSaved(true)
          this.planId = res
          const planAppids = this.chooseChannelList.map(item => {
            return {
              appid: item.appid,
              isDmpAudience: item.isDmpAudience ? 1 : 0,
              inDmpAudience:
                item.crowdPackage.length === 0
                  ? JSON.stringify([])
                  : JSON.stringify(
                    item.crowdPackage.map(item => item.audience_id)
                  ),
              notInDmpAudience:
                item.eliminateCrowd.length === 0
                  ? JSON.stringify([])
                  : JSON.stringify(
                    item.eliminateCrowd.map(item => item.audience_id)
                  ),
              expandTargetingSwitch: item.autoForm
                ? 'EXPAND_TARGETING_SWITCH_OPEN'
                : 'EXPAND_TARGETING_SWITCH_CLOSE',
              expandTargetingSetting: JSON.stringify(item.checkboxArr),
              coldStartAudienceIdList:
                item.chooseFeedCrowd.length === 0
                  ? JSON.stringify([])
                  : JSON.stringify(
                    item.chooseFeedCrowd.map(item => item.audience_id)
                  ),
              coldStartAudienceNames:
                item.chooseFeedCrowd.length === 0
                  ? JSON.stringify([])
                  : JSON.stringify(item.chooseFeedCrowd.map(item => item.name)),
              inDmpAudienceNames:
                item.crowdPackage.length === 0
                  ? []
                  : item.crowdPackage.map(item => item.name),
              notInDmpAudienceNames:
                item.eliminateCrowd.length === 0
                  ? []
                  : item.eliminateCrowd.map(item => item.name)
            }
          })
          pushAppIdCampaign({
            planId: this.planId,
            planAppids
          })
            .then(() => {
              this.$message({
                showClose: true,
                message: '加入投放列表成功!',
                type: 'success'
              })
            })
            .finally(() => {
              this.isShowDialog = false
              this.isShowCrowdPackage = false
              this.isShowFeedCrowd = false
              this.$emit('closeDrawer')
            })
        })
        .catch(() => {
          this.$message({
            showClose: true,
            message: '发送错误，请稍后再试!',
            type: 'error'
          })
        })
        .finally(() => {
          this.setEchoNovelPlanInfo({})
          this.resetFirstStepData()
          this.resetSecondStepData()
          this.isLoading = false
          this.$router.push({
            name: 'DeliveryMaterialManage'
          })
        })
    },
    handleSuccess (crowdList) {
      if (this.type === 1) {
        this.chooseChannelList[this.curIndex].crowdPackage = this.deepClone(
          crowdList
        )
      }
      if (this.type === 2) {
        this.chooseChannelList[this.curIndex].eliminateCrowd = this.deepClone(
          crowdList
        )
      }
    },
    handleFeedSuccess (crowdList) {
      this.chooseChannelList[this.curIndex].chooseFeedCrowd = this.deepClone(
        crowdList
      )
    },
    recursionImageList (topImgs, imgList, count) {
      if (imgList.length === count) {
        return
      }
      const targetIndex = Math.floor(Math.random() * (topImgs.length))
      const target = topImgs[targetIndex].topImg
      topImgs.splice(targetIndex, 1)
      // console.log(topImgs);
      imgList.push(target)
      return this.recursionImageList(JSON.parse(JSON.stringify(topImgs)), imgList, count)
    },
    getParams () {
      const {
        cname,
        bookId,
        bid,
        dayBudget,
        // topImgStr,
        cNum,
        cAdNum,
        autoAcquisitionBudget,
        autoAcquisitionSwitch,
        categoryId
      } = this.secondStepData
      const {
        posType,
        beginTime,
        endTime,
        timeset,
        bidActionType,
        bidStrategy,
        bidObjective,
        planTopImgs,
        education,
        os,
        devicePrice,
        deviceBrandModel,
        telcom,
        connection,
        inActionList,
        notInActionList,
        pageIds,
        planGroup,
        directs,
        selectedList,
        timesetType
      } = this.firstStepData
      const copyPlanGroup = this.deepClone(planGroup)
      if (posType !== 8) {
        copyPlanGroup.desc = JSON.stringify(copyPlanGroup.desc)
        if (posType === 999) {
          this.composePic = [
            ...this.friendCircleList.map(item => {
              return item.imageList
            })
          ]
          copyPlanGroup.composePic = this.composePic
        }
      }
      if (posType === 8) {
        copyPlanGroup.headDescType = 1
        copyPlanGroup.headDescription = ''
      }

      const copyBeginTime = parseInt(beginTime / 1000)
      const copyEndTime = parseInt(endTime / 1000)
      const copyPlanTopImgs = this.deepClone(planTopImgs).filter(
        item => Object.keys(item).length > 0
      )
      // console.log();
      // !bid 的整数位和小数位
      const integerBitsOfBid = `${bid}`.split('.')[0]
      let decimalBitsOfBid = `${bid}`.split('.')[1]
        ? `${bid}`.split('.')[1]
        : null
      if (decimalBitsOfBid) {
        decimalBitsOfBid =
          decimalBitsOfBid.length < 2
            ? `${decimalBitsOfBid}0`
            : decimalBitsOfBid
      }
      const bidValue = `${integerBitsOfBid}${
        decimalBitsOfBid || '00'
      }`

      // ! dayBudget 的整数位和小数位
      const integerBitsOfDayBudget = `${dayBudget}`.split('.')[0]
      let decimalBitsOfDayBudget = `${dayBudget}`.split('.')[1]
        ? `${dayBudget}`.split('.')[1]
        : null

      if (decimalBitsOfDayBudget) {
        decimalBitsOfDayBudget =
          decimalBitsOfDayBudget.length < 2
            ? `${decimalBitsOfDayBudget}0`
            : decimalBitsOfDayBudget
      }
      const dayBudgetValue = `${integerBitsOfDayBudget}${
        decimalBitsOfDayBudget || '00'
      }`

      const obj = {
        cname,
        bookId,
        categoryId,
        bid: Number(bidValue),
        dayBudget: Number(dayBudgetValue),
        topImgStr: this.topImagesArr.join(','),
        planTopImgs: copyPlanTopImgs,
        cNum,
        cAdNum,
        posType,
        beginTime: copyBeginTime,
        endTime: copyEndTime,
        timeset,
        bidActionType,
        bidStrategy,
        bidObjective,
        education,
        os,
        devicePrice,
        deviceBrandModel,
        telcom,
        connection,
        inActionList,
        notInActionList,
        pageIds,
        planGroup: copyPlanGroup,
        directs,
        timesetType,
        selectedList,
        autoAcquisitionSwitch,
        autoAcquisitionBudget
      }
      return obj
    },
    // 选择种子人群
    handleFeedCrowd (row) {
      this.type = 3
      const { appid, mpName } = row
      this.curIndex = this.chooseChannelList.findIndex(
        channel => channel.appid === appid
      )
      this.mpName = mpName
      getWechatRegionCrowd({ appid }).then(res => {
        res.forEach(item => {
          item.label = item.name
          item.key = item.audience_id
        })
        const feedCrowd = res.filter(item => item.type === 'CUSTOMER_FILE')
        this.chooseChannelList[this.curIndex].feedCrowd = this.deepClone(
          feedCrowd
        )
        this.feedCrowd = this.deepClone(feedCrowd)
        this.feedEchoList = this.deepClone(
          this.chooseChannelList[this.curIndex].chooseFeedCrowd
        )
        this.isShowFeedCrowd = true
      })
    },
    resetData (flag = true) {
      this.channelNameList = []
      if (flag) {
        this.options = []
      }
      this.chooseChannelList = []
      this.feedCrowd = []
      this.crowdInfo = []
      this.crowdPackage = []
      this.eliminateCrowd = []
      this.curIndex = null
      this.rightDefaultChecked = []
      this.leftDefaultChecked = []
    },
    nextStep () {
      this.isShowDialog = true
    },
    deepClone (val) {
      return JSON.parse(JSON.stringify(val))
    }
  },
  components: {
    chooseCrowdList,
    chooseFeedCrowd
  }
}
</script>
<style lang="scss" scoped>
h3 {
  font-size: 14px;
}
i {
  color: #c7c7c7;
}
/deep/ .el-select {
  width: 700px;
  .el-select__tags {
    max-width: 700px;
  }
}
/deep/ .el-table__row:hover {
  td {
    background-color: #ffffff !important;
  }
}
/deep/ .el-table__row-- d:hover {
  td {
    background-color: #fafafa !important;
  }
}
/deep/ .el-transfer {
  width: 800px;
}
/deep/ .el-transfer-panel {
  width: 300px;
}
/deep/ .el-transfer-panel__body {
  height: 600px;
  /deep/ .el-transfer-panel__list.is-filterable {
    height: 100%;
  }
}

/deep/ .el-drawer {
  padding: 0 20px 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
  /deep/ .el-drawer__body {
    position: relative;
  }
}
/deep/ .el-pagination {
  margin-top: 20px;
  text-align: right;
}

/deep/ .eleImg {
  width: 16px;
  height: 14px;
}
/deep/ .el-table__body-wrapper {
  overflow: auto !important;
}

.promoted-text{
   word-break:break-all;
}

.button-operation {
  position: absolute;
  left: 50%;
  top: 85%;
  transform: translateX(-50%);
  width: 200px;
  text-align: center;
}
.channel-part {
  .channel-part-container {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: wrap;
    .channel-account-preview {
      margin-right: 20px;
    }
  }
}

.plan-list {
  .plan-list-header {
    width: 100%;
    background: #fff;
  }
  .plan-list-cantainer {
    background: #fff;
    box-sizing: border-box;
    padding: 20px;
    margin-top: 10px;
    min-height: 300px;
    position: relative;
    h3 {
      margin-bottom: 20px;
    }
    > div {
      .friend-circle-preview {
        width: 14%;
        border: 1px solid #f2f2f4;
        border-radius: 4px;
        padding: 20px;
        position: relative;
        margin-right: 30px;
        .top-info {
          // width:10%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          position: relative;
          > div {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            img {
              width: 20%;
              min-width: 30px;
              // height: 40px;
              border-radius: 4px;
              margin-right: 6px;
            }
            > div {
              flex-grow: 1;
              > p:nth-of-type(1) {
                margin-bottom: 4px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .tag {
                  border: 1px solid #a0a0a0;
                  border-radius: 2px;
                  color: #a0a0a0;
                  font-size: 12px;
                  padding: 2px 4px;
                }
              }
            }
          }
        }
        .ad-image {
          box-sizing: border-box;
          padding: 12px 12px 12px 42px;
          .image-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            .image-item {
              margin-bottom: 10px;
              margin-right: 10px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          > p {
            font-size: 12px;
            margin-bottom: 4px;
          }
          > p:nth-of-type(1) {
            color: #576b95;
            margin-top: 4px;
          }
          > p:nth-of-type(2) {
            color: #b2b2b2;
          }
        }
        .mask-hover {
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          display: none;
          background: rgba(0, 0, 0, 0.5);
          > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
              font-size: 24px;
              color: #fff;
              margin-left: 20px;
              cursor: pointer;
            }
          }
        }
        &:hover .mask-hover {
          z-index: 100;
          display: block;
        }
      }
      .channel-account-preview {
        width: 320px;
        border-radius: 4px;
        border: 1px solid #f2f2f4;
        position: relative;
        .placeholder-image {
          img {
            width: 100%;
            height: 163px;
          }
        }
        .channel-account-footer {
          margin: 10px;
          > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .channel-name-info {
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }
            }
            > div:nth-of-type(2) {
              width: 84px;
              background: #f2f2f2;
              text-align: center;
              border-radius: 2px;
              > span {
                height: 32px;
                line-height: 32px;
                color: #06ae56;
              }
            }
          }
        }
        .ad-tags {
          width: 40px;
          height: 20px;
          position: absolute;
          top: 10px;
          left: 10px;
          background: #7a7a7b;
          color: #fff;
          text-align: center;
          span {
            line-height: 20px;
          }
        }
        .mask {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: none;
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
          cursor: pointer;
          i {
            font-size: 24px;
            margin-right: 20px;
          }
        }
        &:hover {
          .mask {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .empty-tips {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ccc;
    }
  }
  .pre-next-step {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.main-container {
  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  .choose-channelName {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .auto-form-label {
      margin-left: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .channel-info {
    margin-top: 20px;
    .crowd {
      > div {
        padding: 4px;

        border: 1px solid #f2f2f2;
        border-radius: 4px;
        > div:nth-of-type(1) {
          span {
            padding: 4px;
            border-radius: 4px;
          }
          span:nth-of-type(2) {
            &:hover {
              cursor: pointer;
              background-color: #e0e0e0;
            }
          }
        }
      }
    }
    .wrapper {
      > div:nth-of-type(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .wrapper-list {
        .wrapper-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          i {
            color: #d6d6d6;
          }
          &:hover {
            background: #f5f5f5;
            i {
              color: #000;
            }
          }
        }
      }
    }
  }
  .cancel-or-confirm {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
